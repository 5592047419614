import './AuthPage.css'
import SignIn from '../../components/SignIn';
import artworkMainAi from '../../resources/artwork_main_ai.png';
import { useNavigate } from 'react-router-dom';
import { getAuthUrl, getGithubAuthUrl } from '../../service/auth';

export function AuthPage() {

    const handleSignIn = (provider) => {
        window.location.replace(getAuthUrl(provider))
    }

    return (
        <div className="main-page">
            <img src={artworkMainAi} alt="Artwork" />
            <SignIn onSignIn={handleSignIn} />
        </div>
    )
}