import { Link, redirect, useNavigate } from "react-router-dom";
import { getJwtPayload, logout } from "../../service/auth";
import Flow from "./Flow";
import './Header.css'

export function Header() {

    const user = getJwtPayload()
    const navigate = useNavigate()

    function onLogout() {
        logout()
        navigate(0)
    }

    return (
        <div className="container">
            <Flow />
            <div className="user-container">
                <Link to={'/profile'}>{user.sub}</Link>
                <button onClick={onLogout}>Logout</button>
            </div>
        </div>
    )
}