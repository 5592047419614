import { useEffect } from "react"
import { redirect, useNavigate, useParams } from "react-router-dom"
import { saveToken } from "../../service/auth"

export function JwtSavePage() {
    const {jwt} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        saveToken(jwt)
        navigate('/')
    }, [jwt])

    return <h3>Authenticating...</h3>
}