import React from 'react';

const TermsOfService = () => (
  <div>
    <h1>Terms of Service</h1>
    {/* Your content here */}
  </div>
);

export default TermsOfService;
