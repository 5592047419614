// SystemDesignInputList.js
import React from 'react';
import DraggableInputItem from './DraggableInputItem';  // <- Don't forget to import!

const SystemDesignInputList = ({ inputs, handleDrop }) => {
    return (
        <div>
          {inputs.map(input => (
            <DraggableInputItem key={input.id} id={input.id} name={input.name} />
          ))}
        </div>
    );
};

export default SystemDesignInputList;
