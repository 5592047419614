import { Link, redirect } from "react-router-dom";
import { getJwtPayload } from "../service/auth";


export function ProfilePage() {
    // Display only info from JWT token
    // TODO: Make call to /profile API in the future

    const user = getJwtPayload()

    if (!user) {
        return redirect('/')
    }

    return (
        <div>
            <Link to='/'>Go back</Link>
            <h1>{user.sub}</h1>
            <h3>{user.platform_login} - {user.platform}</h3>
        </div>
    )
}