import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SystemDesignInputList from '../components/SystemDesignInputList';
import WorkingField  from '../components/WorkingField';
import Flow from '../components/header/Flow';
import InputCardsContext from '../components/InputCardsContext';
import ErrorBoundary  from '../components/ErrorBoundary'; // <-- Make sure to add this import
import './MainPage.css'
import { AuthPage } from './auth/AuthPage';
import { authenticated } from '../service/auth';
import { Header } from '../components/header/Header';

export function MainPage() {

  const isAuthenticated = authenticated()

  const initialInputs = [
    { id: 1, name: 'Availability' },
    { id: 2, name: 'Backup & Recovery' },
    { id: 3, name: 'Cost-efficiency' },
    { id: 4, name: 'Fault-tolerance' },
    { id: 5, name: 'Latency' },
    { id: 6, name: 'Logging' },
    { id: 7, name: 'Maintainability' },
    { id: 8, name: 'Monitoring' },
    { id: 9, name: 'Performance' },
    { id: 10, name: 'Quality' },
    { id: 11, name: 'Redundancy' },
    { id: 12, name: 'Security' },
    { id: 13, name: 'Scalability' },
    { id: 14, name: 'Throughput' },
    { id: 15, name: 'Fault-tolerance' }
  ];

  const [selectedInputs, setSelectedInputs] = useState([]);

  function handleDrop(input) {
    // Ensure the item with the same name isn't already in the list
    if (!selectedInputs.some(i => i.name === input.name)) {
      setSelectedInputs(prev => [...prev, input]);
    }
  }

    return (
      <ErrorBoundary>
        <div className="app">
          {isAuthenticated ? (
            <DndProvider backend={HTML5Backend}>
              <InputCardsContext>
                <Header />
                <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - headerHeight)' }}>
                  <SystemDesignInputList inputs={initialInputs} />
                  <WorkingField inputs={selectedInputs} onDrop={handleDrop} />
                </div>
              </InputCardsContext>
            </DndProvider>
          ) : <AuthPage /> }
        </div>
      </ErrorBoundary>
    );
    

}