import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleGmailCallback } from '../service/auth';  // You'll need to implement this

export function GmailCallbackPage() {
  const { code } = useParams();  // Assuming the OAuth code is passed as a URL parameter
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchToken() {
      try {
        await handleGmailCallback(code);  // You'll need to implement this function in your auth service
        navigate('/');
      } catch (error) {
        console.error('Failed to authenticate:', error);
        navigate('/auth');  // Redirect to the auth page in case of failure
      }
    }
    fetchToken();
  }, [code, navigate]);

  return <h3>Authenticating with Gmail...</h3>;
}
