
const AUTH_KEY = "auth"
const AUTH_URL = process.env.REACT_APP_AUTH_URL || "http://localhost:9000"

export function getToken() {
  return localStorage.getItem(AUTH_KEY)
}

export function saveToken(jwt) {
  localStorage.setItem(AUTH_KEY, jwt)
}

export function logout() {
  localStorage.removeItem(AUTH_KEY)
}

export function authenticated() {
  return !!getToken()
}

export function getJwtPayload() {
  const token = getToken()
  if (!token) {
    return null
  }
  const payload = token.split('.')[1]
  return JSON.parse(atob(payload))
}

export function getAuthUrl(provider) {
  return `${AUTH_URL}/auth/${provider}`
}


export async function handleGmailCallback(code) {
  try {
    // Replace with your API endpoint URL
    const url = `${process.env.REACT_APP_AUTH_URL}/auth/gmail/exchange`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    if (!response.ok) {
      const responseBody = await response.json();
      console.error('Failed to authenticate:', responseBody);
      return false;
    }

    const { jwt } = await response.json();
    saveToken(jwt);  // Assuming saveToken function saves JWT to localStorage or another form of client-side storage
    return true;
  } catch (error) {
    console.error('Failed to authenticate:', error);
    return false;
  }
}
