import React from 'react';

const PrivacyPolicy = () => (
  <div>
    <h1>Privacy Policy</h1>
    {/* Your content here */}
  </div>
);

export default PrivacyPolicy;
