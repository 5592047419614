// Flow.js
import React, { useContext } from 'react';
import { InputCardsContextValue } from '../InputCardsContext';

export default function Flow() {
  const { handleStartClick } = useContext(InputCardsContextValue);

  return (
    <div>
      PICK -> DRAG -> EDIT -> <button onClick={handleStartClick}>START</button>
    </div>
  );
}
