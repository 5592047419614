import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import DraggableResizableCard from './DraggableResizableCard';
import { InputCardsContextValue } from './InputCardsContext';

function WorkingField({ onDrop }) {

  const { inputCards, setInputCards, addInputCard } = useContext(InputCardsContextValue);

  const handleDrop = (item) => {
    // Add the dropped item to the inputCards state
    const newCard = { ...item, description: '' }; // Initialize the description as an empty string
    addInputCard(newCard);
  
    // Call the external onDrop function if provided
    if (onDrop) {
      onDrop(newCard);
    }
  };

  console.log('inputCards:', inputCards); // Log inputCard

  const handleChange = (id, description) => {
    const updatedCards = inputCards.map(card => {
      if (card.id === id) {
        return { ...card, description };
      }
      return card;
    });
    console.log('Updated Cards:', updatedCards); // Log the updated cards
    setInputCards(updatedCards);
  };
  
  

  const [{ isOver }, drop] = useDrop({
    accept: 'input',
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const fieldStyle = {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    padding: '10px',
  };

  return (
    <div ref={drop} style={{ ...fieldStyle, backgroundColor: isOver ? 'lightyellow' : 'lightgray' }}>
      {inputCards && inputCards.map((input, index) => (
        <DraggableResizableCard key={input.id} item={input} onChange={handleChange} index={index} />
      ))}
    </div>
  );

}

export default WorkingField;
