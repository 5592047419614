import React, { useState, createContext } from "react";

// Creating a context
const InputCardsContextValue = createContext(); 

const InputCardsContext = ({ children }) => {
  const [inputCards, setInputCards] = useState([]);

  const handleStartClick = () => {
    // Code to send inputCards to the backend
    console.log(inputCards);
    sendStatement(inputCards);
  };


  const sendStatement = async (inputStatements) => {
    // Create the payload object

    const flattenStatements = (statements) => {
      return statements.map(statement => {
        return {
          id: statement.id,  // or any unique id generator
          name: statement.name,
          description: statement.description
        };
      });
    };

    const flattenedJson = flattenStatements(inputStatements);

    // Generate the checksum using the provided async function
    const statement_checksum = await generateChecksum(flattenedJson)

    console.log("*******>>>>>", flattenedJson);

    const payload = {
      statement: flattenedJson,
      statement_checksum: statement_checksum,
    };

  
    // POST request to an API endpoint:
  
    const url = 'http://localhost:8000/engine/start';

    try {
      const json = JSON.stringify(payload)
      console.log(json);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: json,
      });
  
      // Handle the response as needed
      if (response.ok) {
        console.log('Statement sent successfully');
      } else {
        const responseBody = await response.json();
        console.error('Failed to send statement. Response:', responseBody);
      }
    } catch (error) {
      console.error('Error sending statement:', error);
    }
  };

  async function generateChecksum(payload) {
    const json = JSON.stringify(payload);
    const encoder = new TextEncoder();
    const data = encoder.encode(json);
    const hash = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hash)); 
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

// Function to add a new card
const addInputCard = (card) => {
  // Check if a card with the same id already exists
  const existingCard = inputCards.find(existingCard => existingCard.id === card.id);

  if (!existingCard) { // If not, add the new card
    const newCard = { ...card, description: '' };
    setInputCards([...inputCards, newCard]);
  } else {
    console.warn(`Card with id ${card.id} already exists. Duplicates not allowed.`);
  }
};


  return (
    <InputCardsContextValue.Provider value={{ inputCards, setInputCards, handleStartClick, addInputCard }}>
      {children}
    </InputCardsContextValue.Provider>
  );
};

export default InputCardsContext;
export { InputCardsContextValue }; // Exporting the context value to be used in other components
