// DraggableInputItem.js
import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableInputItem = ({ id, name }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'input',
        item: { id, name },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                console.log("Drag ended with a successful drop!", item);
            } else {
                console.log("Drag ended but didn't drop.", item);
            }
        }
    });

    const itemStyle = {
        padding: '10px 15px',
        margin: '10px 0',
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: isDragging ? 'lightblue' : '#f0f0f0',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    };

    return (
        <div ref={drag} style={itemStyle}>
            {name}
        </div>
    );
};

export default DraggableInputItem;
