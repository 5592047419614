import React from 'react';

import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { JwtSavePage } from './pages/auth/JwtSavePage';
import { ProfilePage } from './pages/ProfilePage';
import { GmailCallbackPage } from './pages/GmailCallbackPage';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />
  },
  {
    path: '/profile', 
    element: <ProfilePage />
  },
  {
    path: '/auth/jwt/:jwt',
    element: <JwtSavePage />
  },
  {
    path: '/auth/gmail/callback/:code',
    element: <GmailCallbackPage />
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  }
])

const root = document.getElementById('root');
const appRoot = createRoot(root);
appRoot.render(<RouterProvider router={router} />);