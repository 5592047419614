import PropTypes from 'prop-types';

function Card({ item, onChange }) {
    const handleContentChange = (e) => {
      onChange(item.id, e.target.value);
    };
  
    const cardStyle = {
      border: '1px solid #ccc',
      padding: '10px',
      borderRadius: '5px',
      overflow: 'hidden',
      position: 'relative'
    };
  
    const textAreaStyle = {
      width: '80%', // Set the width to 80% of the card
      padding: '5px',
      border: '1px solid #ccc',
      borderRadius: '3px',
      resize: 'vertical' // Allow vertical resizing
    };
  
    return (
      <div style={cardStyle}>
        <h3>{item.name}</h3>
        <textarea 
            value={item.description || ''} 
            onChange={handleContentChange} 
            style={textAreaStyle} 
            rows="4" // Set the default number of rows
        />
      </div>
    );
  }

  Card.propTypes = {
        item: PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,  // Update this line
            priority: PropTypes.number, // if priority is optional
            // Add other properties here if the item object has more
        }).isRequired,
    };

  export default Card;