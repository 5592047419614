import React from 'react';
import { useDrag } from 'react-dnd';
import { Resizable } from "re-resizable";
import Card from './Card';

function DraggableResizableCard({ item, onChange }) {
    const [, ref] = useDrag({
      type: 'CARD',
      item: { id: item.id }
    });
  
    // Fifty shades of gray
    const grayShades = [
      "#f0f0f0", "#e0e0e0", "#d0d0d0", "#c0c0c0", "#b0b0b0",
      // Add more shades here
    ];
  
    // Select a gray shade based on the item's ID
    const backgroundColor = grayShades[item.id % grayShades.length];
  
    // Style for the container of the card
    const containerStyle = {
      margin: '10px', // Adds a gap between the cards
      borderRadius: '8px', // Rounds the corners
      backgroundColor: backgroundColor, // Sets the background color
    };
  
    return (
        <div style={containerStyle}>
          <Resizable defaultSize={{ width: '80%', height: 320 }}>
            <div ref={ref}>
              <Card item={item} onChange={onChange} style={{ width: "80%", height: "80%" }} />
            </div>
          </Resizable>
        </div>
      );
  }
  

export default DraggableResizableCard;
